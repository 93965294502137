/* eslint-disable react-hooks/rules-of-hooks */
import moment from 'moment';
import { useIntl } from 'react-intl';

type TGetDateProps = {
  currentMonth: string;
  monthsOfYear: [];
  currentYear: number;
};

const capitalizeString = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const formattedDate = () => {
  const { locale } = useIntl();

  const month = Intl.DateTimeFormat(locale, { month: 'long' }).format(new Date());
  const currentMonth = capitalizeString(month);

  const monthsOfYear = Array.from(Array(12).keys(), (x) => {
    const months = Intl.DateTimeFormat(locale, { month: 'long' }).format(new Date(2023, x));
    return capitalizeString(months);
  });

  const currentYear = new Date().getFullYear();

  return {
    currentMonth,
    monthsOfYear,
    currentYear,
  } as TGetDateProps;
};

export const monthsOfYear = (locale) => {
  return Array.from(Array(12).keys(), (x) => {
    const months = Intl.DateTimeFormat(locale, { month: 'long' }).format(new Date(2023, x));
    return capitalizeString(months);
  });
};

export const formatDateText = (date: string, messageId: string) => {
  const { formatMessage } = useIntl();

  let day, month, year;
  try {
    ({ day, month, year } = getFormattedDate(date));
  } catch (error) {
    console.error('Failed to convert date', error);
    day = 'unknown';
    month = 'unknown';
    year = 'unknown';
  }

  if (!date || day === 'unknown' || month === 'unknown' || year === 'unknown') {
    return '---';
  } else {
    return formatMessage({ id: messageId })
      .replace('%day', day)
      .replace('%month', formatMessage({ id: `components.abbreviatedMonthNames.m${month}` }))
      .replace('%year', year);
  }
};

export const getFormattedDate = (date: string) => {
  if (date) {
    const [year, month, dayOfMonth] = date?.substring(0, 10)?.split('-') ?? ['', '', ''];
    let dayWithoutZero;
    if (dayOfMonth?.startsWith('0')) {
      dayWithoutZero = dayOfMonth.substring(1);
    } else {
      dayWithoutZero = dayOfMonth;
    }

    if (!year || !month || !dayWithoutZero) {
      console.error(`Invalid date format. Expected 'YYYY-MM-DD', got '${date}'`);
      return { year: 'unknown', month: 'unknown', day: 'unknown' };
    } else {
      return { year: year, month: month, day: dayWithoutZero };
    }
  }
  return {};
};

const convertStringToDate = (dateString: string) => {
  const dateObj = new Date(dateString);
  if (isNaN(dateObj.getTime())) {
    throw new Error(`Invalid date: ${dateString}`);
  }
  return new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
};

const formatDateString = (dateObj: Date, locale: string) => {
  const year = dateObj.getFullYear().toString();
  const month = dateObj.toLocaleDateString(locale, { month: 'short' });
  const dayOfMonth = dateObj.getDate().toString();
  const day = ['en-US', 'en-ZA'].includes(locale) ? dayOfMonth : dayOfMonth.padStart(2, '0');
  return { year, month, day };
};

export const getStringDatesToShortFormat = (
  startDate: string,
  endDate: string,
  locale = 'en_US'
) => {
  try {
    const formattedLocale = locale.replace('_', '-');
    const startDateObj = convertStringToDate(startDate);
    const endDateObj = convertStringToDate(endDate);

    const start = formatDateString(startDateObj, formattedLocale);
    const end = formatDateString(endDateObj, formattedLocale);

    const { year: startYear, month: startMonth, day: startDay } = start;
    const { year: endYear, month: endMonth, day: endDay } = end;

    return {
      startYear,
      startMonth,
      startDay,
      endYear,
      endMonth,
      endDay,
    };
  } catch (error) {
    const startYear = '';
    const startMonth = '';
    const startDay = '';
    const endYear = '';
    const endMonth = '';
    const endDay = '';

    return {
      startYear,
      startMonth,
      startDay,
      endYear,
      endMonth,
      endDay,
    };
  }
};

export const convertDateToLocalDate = (date: string, format?: string) => {
  const dateObj = new Date(date);
  const d = new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const year = d.getFullYear().toString();
  let formattedDate = '';
  if (format === 'monthYear') {
    formattedDate = `${month}${year}`;
  } else {
    const day = d.getDate().toString().padStart(2, '0');
    formattedDate = `${day}${month}${year}`;
  }
  return formattedDate;
};

export const getDate = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const timezoneDate = new Date(
    new Date().toLocaleString('en-US', {
      timeZone,
    })
  );

  return `${moment(timezoneDate).format()}`;
};
