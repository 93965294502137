import { Tooltip } from '@hexa-ui/components';
import { useEffect, useRef, useState } from 'react';
import { ClampLinesProps } from './ClampLines.props';
import { ClampLinesWrapper } from './ClampLines.style';

const ClampLines = ({ text, lines = 1, hasTooltip = true, placement = 'top' }: ClampLinesProps) => {
  const clampElement = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const isEllipsisActive = () => {
    const { scrollHeight, clientHeight } = clampElement.current ?? {};
    return scrollHeight > clientHeight;
  };

  useEffect(() => {
    const handleResize = () => {
      setShowTooltip(isEllipsisActive());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const clampLinesContent = () => (
    <ClampLinesWrapper
      ref={clampElement}
      style={{ WebkitLineClamp: lines }}
      onMouseEnter={() => isEllipsisActive() && setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {text}
    </ClampLinesWrapper>
  );

  if (hasTooltip && showTooltip) {
    return (
      <Tooltip text={text} placement={placement}>
        {clampLinesContent()}
      </Tooltip>
    );
  }

  return clampLinesContent();
};

export default ClampLines;
