import { currencyCode } from '@utils';
import { ICurrencyProps } from './Currency.props';

export const Currency = ({
  country,
  value,
  customLocale,
  customCurrency,
}: ICurrencyProps): JSX.Element => {
  const hasDecimals = !Number.isInteger(value);
  const precision = hasDecimals ? 2 : 0;
  if (customLocale === 'en-ZA' || country === 'ZA') {
    return (
      <>
        {new Intl.NumberFormat(customLocale || currencyCode[country].locale, {
          style: 'currency',
          currency: customCurrency || currencyCode[country].currency,
          minimumFractionDigits: precision,
          maximumFractionDigits: precision,
        })
          .format(value)
          .replace(/,/g, ' ')
          .replace('.', ',')}
      </>
    );
  } else {
    return (
      <>
        {new Intl.NumberFormat(customLocale || currencyCode[country].locale, {
          style: 'currency',
          currency: customCurrency || currencyCode[country].currency,
          minimumFractionDigits: precision,
          maximumFractionDigits: precision,
        }).format(value)}
      </>
    );
  }
};

export const CurrencyText = ({ country, value, customLocale, customCurrency }: ICurrencyProps) => {
  const hasDecimals = !Number.isInteger(value);
  const precision = hasDecimals ? 2 : 0;
  const currencyAccorddingLocale = new Intl.NumberFormat(
    customLocale || currencyCode[country].locale,
    {
      style: 'currency',
      currency: customCurrency || currencyCode[country].currency,
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    }
  ).format(value);

  const currencyForZA = currencyAccorddingLocale.replace(/,/g, ' ').replace('.', ',');

  if (customLocale === 'en-ZA' || country === 'ZA') {
    return currencyForZA;
  } else {
    return currencyAccorddingLocale;
  }
};
