import { styled } from '@hexa-ui/theme';

export const ClampLinesWrapper = styled('span', {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  maxWidth: '110px',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
});
